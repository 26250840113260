import { useStaticQuery, graphql } from 'gatsby';
import './calendar.css';
import './style.css';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/lt';
import DayPicker from 'react-day-picker';

export const CalendarGoogle = (props: any) => {
  const data = useStaticQuery(graphql`
    query MyCalendarQuery {
      allCalendar {
        edges {
          node {
            childrenCalendarEvent {
              start {
                date
                dateTime
              }
              end {
                date
                dateTime
              }
            }
          }
        }
      }
    }
  `);

  const reservedDays = data.allCalendar.edges[0].node.childrenCalendarEvent.map(
    ev => [new Date(ev.start.date), new Date(ev.end.date)]
  );

  const renderDay = day => {
    const date = day.getDate();
    const disabled =
      reservedDays.find(
        range =>
          day.getDate() >= range[0].getDate() &&
          day.getDate() < range[1].getDate()
      ) != null;

    return (
      <div
        className={`DayPicker-Day${disabled ? '--disabled' : ''}`}
        title={disabled ? 'Rezervuota' : 'Laisva'}
      >
        {date}
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <main className="calendar-container-content">
        <DayPicker
          initialMonth={new Date()}
          localeUtils={MomentLocaleUtils}
          locale={'lt'}
          renderDay={renderDay}
        />
      </main>
    </div>
  );
};
