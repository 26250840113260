import React from 'react';
import Heading from '../heading';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Flex, Box } from '@rebass/grid/emotion';
import Container from '../container';

const AboutSection = styled.section`
  text-align: center;
  padding: ${(props: any) => props.theme.spacing[2]}rem 0;
  .about-content {
    text-align: center;
    font-size: 25px;
  }

  .gatsby-image-wrapper {
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  }
`;

function About() {
  const data = useStaticQuery(graphql`
    {
      mdx(frontmatter: { type: { eq: "apie" } }) {
        body
        frontmatter {
          title
        }
      }
    }
  `);
  return (
    <AboutSection>
      <Container>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box m={3}>
            <div className="about-content">
              <MDXRenderer>{data.mdx.body}</MDXRenderer>
            </div>
          </Box>
        </Flex>
      </Container>
    </AboutSection>
  );
}

export default About;
