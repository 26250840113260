import React from 'react';
import Heading from '../heading';
import styled from '@emotion/styled';
import Container from '../container';
import { Flex, Box } from '@rebass/grid/emotion';
import ContactInfo from '../contactInfo';
import { useStaticQuery, graphql } from 'gatsby';
import { CalendarGoogle } from '../calendar/calendar';

const ContactSection = styled.section`
  text-align: center;
  padding: ${(props: any) => props.theme.spacing[2]}rem 0;
`;
function Contact() {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          facebookUsername
          instagramUsername
          address
          phone
          email
          mapUrl
        }
      }
    }
  `);
  return (
    <ContactSection id="contact">
      <Container>
        <Heading>Susisiekite</Heading>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box m={[2, 3]} p={[3, 4]} width={[1, 1 / 3]}>
            <ContactInfo>
              <p>{data.site.siteMetadata.phone}</p>
              <p>{data.site.siteMetadata.email}</p>
              <p>{data.site.siteMetadata.address}</p>
              <br />
              {/* <a
                href={`https://facebook.com/${data.site.siteMetadata.facebookUsername}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                Facebook
              </a> */}
            </ContactInfo>
          </Box>
          <Box m={[2, 3]} width={[1, 2 / 3]}>
            <iframe
              title={`google map of ${data.site.siteMetadata.address}`}
              src={data.site.siteMetadata.mapUrl}
              width="100%"
              height="450"
              frameBorder="0"
            ></iframe>
          </Box>
        </Flex>
        <Flex flexWrap={['wrap', 'nowrap']}>
          <Box m={[1]} width={[1]}>
            <h4>Rezervacijų kalendorius</h4>
            <CalendarGoogle />
          </Box>
        </Flex>
      </Container>
    </ContactSection>
  );
}

export default Contact;
