import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Container from '../container';
import Heading from '../heading';
import styled from '@emotion/styled';
import ImageGallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

const GallerySection = styled.section`
  text-align: center;
  padding: ${(props:any) => props.theme.spacing[2]}rem 0;
`;
export const GalleryWithData = () => {
  const data = useStaticQuery(graphql`
  query {
    allFile(
        filter: { sourceInstanceName: { eq: "content/gallery" } },
        sort: { order: ASC, fields: name }
    ) {
      nodes {
        id
        name
        publicURL
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
              originalName
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
`);
  const images = data.allFile.nodes.map((node) => node.childImageSharp)
  //@ts-ignore
  return <GallerySection id="gallery"><Heading>Galerija</Heading><Container><ImageGallery images={images} /></Container></GallerySection>
}